<script setup>
import { ref } from 'vue';
import AppMenuItem from './AppMenuItem.vue';

const model = ref([
    {
        label: 'Ps Customer Server',
        icon: 'pi pi-home',
        items: [
            {
                label: 'View',
                icon: 'pi pi-chart-bar',
                to: '/ps-customer-server/list'
            }
        ]
    },
    {
        label: 'Site assessment',
        icon: 'pi pi-desktop',
        items: [
            {
                label: 'View',
                icon: 'pi pi-chart-bar',
                to: '/site-assessment/list'
            }
        ]
    },
    {
        label: 'Transformer',
        icon: 'pi pi-cog',
        items: [
            {
                label: 'View',
                icon: 'pi pi-chart-bar',
                to: '/transformer/list'
            }
        ]
    }
]);
</script>

<template>
    <ul class="layout-menu">
        <template v-for="(item, i) in model" :key="item">
            <AppMenuItem :item="item" root :index="i" />

            <li class="menu-separator"></li>
        </template>
    </ul>
</template>

<style lang="scss" scoped></style>
